import { cn } from '@hapstack/common'
import type { User } from '@hapstack/db'
import { Avatar } from '@hapstack/ui'
import { Link } from '@remix-run/react'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import type { ComponentProps } from 'react'

import { useAuthenticatedUser } from '~/routes/_dashboard/useAuthenticatedUser'

const avatarStyles = cva('rounded-full', {
  variants: {
    size: {
      xxxs: 'w-3 text-xxxs',
      xxs: 'w-4 text-xxxs',
      xs: 'w-6 text-xxs',
      sm: 'w-7 text-xs',
      md: 'w-9 text-base',
      lg: 'w-11 text-lg',
      xl: 'w-14 text-xl',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

type AvatarStyleProps = VariantProps<typeof avatarStyles>

type AvatarProps = AvatarStyleProps & ComponentProps<typeof Avatar>

const UserAvatar = ({ size, className, ...props }: AvatarProps) => {
  return (
    <Avatar
      className={cn(avatarStyles({ size }), className)}
      {...props}
    />
  )
}

const UserName = ({
  id,
  name,
  className,
}: {
  id: User['id']
  name: string | null
  className?: string
}) => {
  const { role } = useAuthenticatedUser()

  return (
    <div
      className={cn(
        'font-medium underline-offset-2 hover:underline',
        className
      )}
    >
      {role === 'admin' ? (
        <Link
          to={`/team/${id}`}
          className="block max-w-52 font-medium"
        >
          <div className="truncate">{name || 'Unknown user'}</div>
        </Link>
      ) : (
        <div className="truncate">{name || 'Unknown user'}</div>
      )}
    </div>
  )
}

const UserEmail = ({
  email,
  className,
}: {
  email: string
  className?: string
}) => {
  return (
    <div className={cn('-mt-1 text-sm text-muted-foreground', className)}>
      {email}
    </div>
  )
}

const User = ({ className, children, ...props }: ComponentProps<'div'>) => {
  return (
    <div
      className={cn('flex items-center gap-2', className)}
      {...props}
    >
      {children}
    </div>
  )
}

export { User, UserAvatar, UserEmail, UserName }
